import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Nos services - Les services de Speedy Motorbike Workshop
			</title>
			<meta name={"description"} content={"Chaque moto a son propre esprit, et chez Speedy, nous avons l'expertise nécessaire pour répondre au rythme cardiaque unique de chacune d'entre elles."} />
			<meta property={"og:title"} content={"Nos services - Les services de Speedy Motorbike Workshop"} />
			<meta property={"og:description"} content={"Chaque moto a son propre esprit, et chez Speedy, nous avons l'expertise nécessaire pour répondre au rythme cardiaque unique de chacune d'entre elles."} />
			<meta property={"og:image"} content={"https://parischics.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://parischics.com/img/dll2.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://parischics.com/img/dll2.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://parischics.com/img/dll2.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://parischics.com/img/dll2.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://parischics.com/img/dll2.png"} />
			<meta name={"msapplication-TileImage"} content={"https://parischics.com/img/dll2.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Des services de précision pour votre moto
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Chaque moto a son propre esprit, et chez Speedy, nous avons l'expertise nécessaire pour répondre au rythme cardiaque unique de chacune d'entre elles. Grâce à notre gamme de services, votre moto est non seulement plus performante, mais elle devient aussi une véritable représentation de votre passion pour la conduite.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Contactez-nous
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://parischics.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="40%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://parischics.com/img/5.jpg"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					padding="40px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="--primary" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Nos services
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
						Découvrez notre liste complète de services
					</Text>
					<Box
						min-width="100px"
						margin="30px 0px 30px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Mises au point complètes :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Des mises au point régulières pour des performances optimales, afin que chaque sortie soit meilleure que la précédente.
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Reconstruction et révision de moteurs :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Rétablissement de la puissance et des performances grâce à des reconstructions de moteurs de précision.
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Réglages de la suspension :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Réglages personnalisés de la suspension pour une conduite plus souple et une meilleure maniabilité.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Peintures personnalisées :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Faites-vous remarquer avec une nouvelle peinture personnalisée qui reflète votre style.
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Services liés au système électrique :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Mises à niveau et réparations pour assurer le bon fonctionnement des systèmes électriques de votre vélo.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Installation d'accessoires :{" "}
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Des nouvelles poignées aux systèmes d'échappement, nous installons toute une gamme d'accessoires.
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Inspections de sécurité :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Inspections détaillées pour s'assurer que votre vélo répond à toutes les normes de sécurité.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Services de pneus :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Montage, équilibrage et alignement pour une adhérence solide sur toutes les routes.
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Modifications des performances :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Du réglage de la puce à l'amélioration du système d'échappement, nous améliorons les performances de votre moto.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Restauration de motos classiques :
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Notre expertise en matière de restauration nous permet de redonner vie aux belles bicyclettes classiques.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Pourquoi les cyclistes choisissent Speedy
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							- Des experts dévoués : L'expertise technique de notre équipe n'a d'égale que notre passion pour les motos.
							<br />
							<br />
							- Qualité sans compromis : Nous utilisons des pièces et des fluides de première qualité pour tous les services.
							<br />
							<br />
							- Soins personnalisés : Des plans de service sur mesure pour répondre aux besoins spécifiques de votre moto.
							<br />
							<br />
							- L'accent est mis sur la communauté : Nous sommes des motards au service des motards, un centre communautaire pour ceux qui vivent pour rouler.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Rejoignez les rangs des motards qui font confiance à Speedy - là où le service rencontre l'âme de la machine.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://parischics.com/img/6.jpg"
					max-width="400px"
					border-radius="8px"
					height="100%"
					object-fit="cover"
					lg-width="100%"
					lg-max-width="none"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});